import React from "react";

import "../styles/centered-box.less";

import { Layout, Row, Col } from "antd";

import HeaderPatinette from "../components/header";
import FooterPatinette from "../components/footer";

const { Content } = Layout;

//const sexeForm = Form.create({ name: "homeform" })(TemplateHomeForm)

export default function QuiSommesNous() {
  return (
    <Layout className="centered-box">
      <HeaderPatinette />
      <Content className="content">
        <Row type="flex" justify="center">
          <Col lg={16} xs={24} className="main">
            <h2>Qui sommes-nous ?</h2>
            <Row>
              <Col xs={24} style={{ textAlign: "left" }}>
                <h3>Notre raison d'être : "Vous bougez ? On assure".</h3>
                <p>
                  Patinette veut accompagner le développement des nouvelles
                  pratiques de mobilités en facilitant l'accès à deux piliers
                  essentiels aux riders : la sécurité et la facilité d'usage.
                  D'où une offre co-construite avec des riders, pour associer
                  assurance et services... ...et continuer d'innover demain !
                </p>
                <p>
                  D'ailleurs, si vous avez des idées, n'hésitez pas à nous les
                  partager sur{" "}
                  <a href="mailto:contact@patinette.io">contact@patinette.io</a>
                  .
                </p>
                <h3>L'équipe :</h3>
                <p>
                  <u>R.</u> : Dans une autre vie, R. a construit les activités
                  assurance de BlaBlaCar dans 22 pays. Il s’est confronté à
                  nombre de problématiques communes avec la nouvelle mobilité :
                  méconnaissance de l’obligation d’assurance, absence
                  d’historique de sinistralité, solutions de marche quasi
                  inexistantes. Tout comme les trajets en covoiturage , les
                  nouveaux engins tels que les trottinettes méritent pourtant
                  une protection simple, transparente et accessible.
                </p>
                <p>
                  <u>T. </u>: T. a fait ses premières armes en tant
                  qu’entrepreneur sur un projet de plateforme dédiée aux
                  intermédiaires en assurance. Il a ensuite rejoint l’équipe
                  assurance d'un cabinet de stratégie, pour travailler sur les
                  problématiques en France et dans le monde de tous les acteurs
                  de l'écosystème (assureurs, assisteurs, réassureurs,
                  courtiers, …) sur la mobilité, la maison, la santé et la vie.
                  Coach d’insurtechs, il a acquis la conviction qu’il fallait
                  simplifier la consommation d’assurance et l’adapter aux
                  nouveaux comportements.
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Content>
      <FooterPatinette />
    </Layout>
  );
}
